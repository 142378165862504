import React, { useState } from "react";
import Slider from "react-slick";
const settings = { 
  dots: false,
  arrows: false,
  infinite: true,
  speed: 3501,  
  slidesToShow: 3,
  slidesToScroll: 1,
  initialSlide: 0,   
  cssEase: 'linear',  
  easing: 'ease-in-out',
  // pauseOnHover: false,  
  autoplay: true,
  autoplaySpeed: 3500,  
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: false,
          speed: 2000,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
          infinite: true,
          arrows: false,
          speed: 2000,
        },
      },
      {
        breakpoint: 575,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          arrows: false,
          speed: 2000,
        },
      },
    ],
  };
  const WorkProcessTwo = () => { 
    const [showAdditionalServices1, setShowAdditionalServices1] = useState(false);
    const [showAdditionalServices2, setShowAdditionalServices2] = useState(false); 
    const [showAdditionalServices3, setShowAdditionalServices3] = useState(false); 
    const [showAdditionalServices4, setShowAdditionalServices4] = useState(false); 
    const [showAdditionalServices5, setShowAdditionalServices5] = useState(false); 
    const [showAdditionalServices6, setShowAdditionalServices6] = useState(false); 
  
    const toggleAdditionalServices = (index) => { 
      if (index === 1) {
        setShowAdditionalServices1(!showAdditionalServices1);
      } else if (index === 2) {
        setShowAdditionalServices2(!showAdditionalServices2);
      } else if (index === 3) {
        setShowAdditionalServices3(!showAdditionalServices3);
      } else if (index === 4) {
        setShowAdditionalServices4(!showAdditionalServices4);
      } else if (index === 5) {
        setShowAdditionalServices5(!showAdditionalServices5);
      } else if (index === 6) {
        setShowAdditionalServices6(!showAdditionalServices6);
      } 
    };
  return (
    <>
      {/*==================== Work Process Two start ====================*/}
      <div className='work-process-area pd-top-200 pd-bottom-90'>
        <div className='container'>
          <div className='section-title text-center'>
            <h6 className='sub-title'>OUR PRODUCT</h6>
            <h4 className='title'>
              CHECK OUR ALL
            </h4>
            <h2 className='title'>
              <span>Digital</span> Products
            </h2>
          </div>
          <div className='row'>
            <div className='col-lg-4 col-md-6'>
              <div className='single-service-inner text-center'>
                <div className='thumb'>
                  <img src='assets/img/service/vrbt.png' alt='img' />
                </div>
                <div className='details'>  
                  <h5>
                     <a href="/service"> VRBT(Video Ring Back Tone) </a>
                  </h5>  

                  <div className={`additional-services ${showAdditionalServices1 ? 'show' : ''}`}>
                    <hr></hr>
                    <div className='single-work-process-inner'>
                      <div className="mb-3">
                      <h3 className={'color'}><a href="#" target="_blank">Coming Soon</a></h3>
                      </div>
                    </div>
                    <hr></hr>
                  </div>

                  <a className='btn btn-border-base' onClick={() => toggleAdditionalServices(1)}>
                        {showAdditionalServices1 ? "Show Less" : "Show Product"}
                  </a>
                </div>
              </div>
            </div> 
            
            <div className='col-lg-4 col-md-6'>
              <div className='single-service-inner text-center'>
                <div className='thumb'>
                 <img src='assets/img/service/esport.png' alt='img' />
                </div>
                <div className='details'> 
                  <h5>
                    <a href="/service">ESPORTS</a>
                  </h5> 
                  
                  <div className={`additional-services ${showAdditionalServices2 ? 'show' : ''}`}>
                    <hr></hr>
                    <div className='single-work-process-inner'>
                      <div className="mb-3">
                      <h3 className={'color'}> 1.<a href="https://www.engagewinner.com/home" target="_blank">Enagage</a></h3>
                      </div>
                    </div>
                    <hr></hr>
                  </div>

                    <a className='btn btn-border-base' onClick={() => toggleAdditionalServices(2)}>
                        {showAdditionalServices2 ? "Show Less" : "Show Product"}
                    </a>
                 </div>
              </div>
            </div>

            <div className='col-lg-4 col-md-6'>
              <div className='single-service-inner text-center'>
                <div className='thumb'>
                 <img src='assets/img/service/wap.png' alt='img' />
                </div>
                <div className='details'> 
                  <h5>
                    <a href="/service" >WAP Services</a>
                  </h5>

                  <div className={`additional-services ${showAdditionalServices3 ? 'show' : ''}`}>
                    <hr></hr>
                    
                   <div className='single-work-process-inner'>
                    <div className="mb-3">
                     <h3 className={'color'}> 1. <a href="http://www.funboxbd.com/club/" target="_blank">Funbox</a> </h3>
                    </div>
                  </div>
                  <hr></hr>

                  <div className='single-work-process-inner'>
                   <div className="mb-3">
                   <h3 className={'color'}> 2. <a href="http://gameboxbd.net/?mkeyid=d2lmaQ==" target="_blank">GameZone</a></h3>
                   </div>
                  </div>
                  <hr></hr>

                  </div>

                  <a className='btn btn-border-base' onClick={() => toggleAdditionalServices(3)}>
                    {showAdditionalServices3 ? "Show Less" : "Show Product"}
                  </a>

                </div>
              </div>
            </div>

            <div className='col-lg-4 col-md-6'>
              <div className='single-service-inner text-center'>
                <div className='thumb'>
                  <img src='assets/img/service/ivr.png' alt='img' />
                </div>
                <div className='details'> 
                  <h5>
                    <a href='/service'>IVR</a>
                  </h5> 

                  <div className={`additional-services ${showAdditionalServices4 ? 'show' : ''}`}>
                    <hr></hr>
                    <div className='single-work-process-inner'>
                      <div className="mb-3">
                      <h3 className={'color'}> 1. <a href="/mvas">Islamer Alo</a></h3>
                      </div>
                    </div>
                    <hr></hr>
                  </div>

                  <a className='btn btn-border-base' onClick={() => toggleAdditionalServices(4)}>
                        {showAdditionalServices4 ? "Show Less" : "Show Product"}
                  </a>
                   
                </div>
              </div>
            </div>

            <div className='col-lg-4 col-md-6'>
              <div className='single-service-inner text-center'>
                <div className='thumb'>
                  <img src='assets/img/service/sms.png' alt='img' />
                </div>
                <div className='details'> 
                  <h5>
                  <a href='/service'>SMS</a>
                  </h5>
                  
                  <div className={`additional-services ${showAdditionalServices5 ? 'show' : ''}`}>
                    <hr></hr>
                    <div className='single-work-process-inner'>
                      <div className="mb-3">
                        <h4 className={'color'}>&#9679; Life hacks tips </h4>
                        <hr></hr>
                        <h4 className={'color'}>&#9679; Kotha sms tips</h4>
                        <hr></hr>
                        <h4 className={'color'}>&#9679; Al-quran Ayat</h4>
                      </div>
                    </div>
                    <hr></hr>
                  </div>

                  <a className='btn btn-border-base' onClick={() => toggleAdditionalServices(5)}>
                        {showAdditionalServices5 ? "Show Less" : "Show Product"}
                  </a>
                </div>
              </div>
            </div>
             
             
            <div className='col-lg-4 col-md-6'>
              <div className='single-service-inner text-center'>
                <div className='thumb'>
                  <img src='assets/img/service/entertainment.png' alt='img' />
                </div>
                <div className='details'> 
                  <h5>
                    <a href="/service">Entertainment</a>
                  </h5>  

                  <div className={`additional-services ${showAdditionalServices6 ? 'show' : ''}`}>
                    <hr></hr>
                    <div className='single-work-process-inner'>
                      <div className="mb-3">
                      <h3 className={'color'}> 1. <a href="https://www.youtube.com/channel/UCwv7XYUHis1WGaaeC4MD_NA" target="_blank">Gaanbox</a></h3>
                      </div>
                    </div>
                    <hr></hr>
                  </div>

                  <a className='btn btn-border-base' onClick={() => toggleAdditionalServices(6)}>
                        {showAdditionalServices6 ? "Show Less" : "Show Product"}
                  </a>
                </div>
              </div>
            </div>

        </div>
      </div>
      </div>
      {/* ==================== Work Process Two end ====================*/}
    </>
  );
};

export default WorkProcessTwo;
