import React, { useState, useEffect } from "react";
import NavbarTwo from "../components/NavbarTwo";
import Breadcrumb from "../components/Breadcrumb";
import TermCondition from "../components/TermCondition"; 
import Footer from "../components/Footer";

const Blog = () => {
  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const isScrolled = window.scrollY > 0;
      setScrolled(isScrolled);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return (
    <>
      {/* Navigation Bar */}
      <NavbarTwo transparent={!scrolled}/>

      {/* Breadcrumb */}
      <Breadcrumb title={"Terms & Conditions"} />

      {/* Terms&Conditions */}
       <TermCondition />

      {/* Footer */}
      <Footer />
    </>
  );
};

export default Blog;
