let serviceList = [
  {
    title: "VRBT(Video Ring Back Tone)",
    des: "   V-RBT works just in a simple way as the existing Caller Tune works. Now Dialer will be able set his/her favorite video clips and the receiver will be able to view the video.",
    img: "assets/img/service/vrbt.png",
    route:'/product',
  },
  {
    title: "ESPORTS",
    des: "Esports is a form of competition using video games & often takes the form of organized, multiplayer competitions, particularly between professional players, individually or as teams.",
    img: "assets/img/service/esport.png",
    route:'/product',
  },
  {
    title: "IVR",
    des: "  IVR is technology that allows incoming callers to access information via a voice response system of prerecorded messages without having to wait. ",
    img: "assets/img/service/ivr.png",
    route:'/product',
  },

  {
    title: "WAP Services",
    des: "WAP is a communications protocol that is used for wireless data access through most mobile wireless networks.",
    img: "assets/img/service/wap.png",
    route:'/product',
  },
  {
    title: "SMS Services",
    des: "SMS, is a text messaging service component of most telephone, Internet and mobile device systems.",
    img: "assets/img/service/sms.png",
    route:'/product',
  },
  {
    title: "Entertainment",
    des: " Entertainment is a form of activity that holds the attention and interest of an audience or gives pleasure and delight.",
    img: "assets/img/service/entertainment.png",
    route:'/product',
  },
  
  
   
];

export default serviceList;
