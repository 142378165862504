import React from "react";
import Slider from "react-slick";
const WorkProcessFour = () => {
  const settings = {
    dots: true,
    arrows: false,
    infinite: true,
    speed: 1000,
    slidesToShow: 3,
    slidesToScroll: 3  ,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
          arrows: false,
        },
      },
      {
        breakpoint: 575,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
        },
      },
    ],
  };
  return (
    <>
      {/*==================== Work Process Three start ====================*/}
      <div
        className='work-process-area bg-position-right pd-top-200 pd-bottom-90'
        style={{ backgroundImage: 'url("./assets/img/bg/16.png")' }}
      >
        <div className='container'>
          <div className='row justify-content-center'>
            <div className='col-lg-6'>
              <div className='section-title text-center pb-5'>
                <h6 className='sub-title-sky-blue'>OUR MVAS SERVICES</h6>
                <h2 className='title'>
                   Dial <span>16265</span> For IVR Service
                </h2>
              </div>
            </div>
          </div>
          <div className='work-process-area-inner-2'>
            <div className='row'>
            <Slider {...settings}> 
              <div className='col-lg-3 col-md-6 mb-3'>
              <img
                    className='line-img'
                    src="assets/img/mvas_image/banglabox-phn.png" 
                    alt='img'
                  />
              </div>

              <div className='col-lg-3 col-md-6 mb-3'>
              <img
                    className='line-img'
                    src="assets/img/mvas_image/cycas-phn.png"
                    alt='img'
                  />
              </div>

              <div className='col-lg-3 col-md-6 mb-3'>
              <img
                    className='line-img'
                    src="assets/img/mvas_image/daroonto-phn.png"
                    alt='img'
                  />
              </div>

              <div className='col-lg-3 col-md-6 mb-3'>
              <img
                    className='line-img'
                    src="assets/img/mvas_image/funbox-phn.png"
                    alt='img'
                  />
              </div>

              <div className='col-lg-3 col-md-6 mb-3'>
              <img
                    className='line-img'
                    src="assets/img/mvas_image/Gamezone-phn.png"
                    alt='img'
                  />
              </div> 
              </Slider>
            </div>
          </div>
        </div>
      </div>

      {/* ==================== Work Process Three end ====================*/}
    </>
  );
};

export default WorkProcessFour; 