import React from "react"; 

const ServiceAreaGroup = () => {
  return (
    <>
      {/*=================== service area start ===================*/}
      <div className='service-area bg-relative pd-top-100'>
        <img
          className='position-bottom-left top_image_bounce'
          src='assets/img/icon/4.png'
          alt='img'
        />
        <div className='container'>
         <p> Welcome to Adbox Bangladesh! These terms and conditions outline the rules and regulations for the use of Adbox's website and the services we provide.
              By accessing this website, we assume you accept these terms and conditions. Do not continue to use Adbox's website if you do not agree to take all of the terms and conditions stated on this page.</p>
            <h4>1. License to Use</h4>   
              <p>  1.1. Unless otherwise stated, Adbox Bangladesh and/or its licensors own the intellectual property rights for all material on Adbox's website. All intellectual property rights are reserved.</p>  
              <p>  1.2. You may view and/or print pages from Adbox's website for your personal use, subject to restrictions set in these terms and conditions.</p><br></br> 

            <h4>2. Restrictions</h4> 
              <p> 2.1. You are specifically restricted from all of the following:</p>
              <p> a. Publishing any material from Adbox's website in any other media. </p> 
              <p> b. Selling, sublicensing, and/or otherwise commercializing any material from Adbox's website. </p> 
              <p> c. Using Adbox's website in a way that is damaging, or that could be damaging to the website. </p> 
              <p> d. Engaging in any data mining, data harvesting, data extracting, or any other similar activity in relation to Adbox's website. </p> 
              <p> e. Using Adbox's website to engage in any advertising or marketing. </p><br></br> 

            <h4>3. No Warranties</h4>
              <p> 3.1. This website is provided "as is," with all faults, and Adbox Bangladesh makes no express or implied representations or warranties, of any kind related to this website or the materials contained on this we</p>
              <p> 3.2. Additionally, nothing contained on this website shall be construed as providing consult or advice to you.</p><br></br>

            <h4>4. Limitation of Liability</h4>
              <p> 4.1. In no event shall Adbox Bangladesh, nor any of its officers, directors, and employees, be held liable for anything arising out of or in any way connected with your use of this website, whether such liability is under contract.</p><br></br>
                           
            <h4>5. Indemnification</h4>
              <p> 5.1. You hereby indemnify to the fullest extent Adbox Bangladesh from and against any and/or all liabilities, costs, demands, causes of action, damages, and expenses arising in any way related to your breach of any of the provisions of these terms.</p><br></br>
                       
            <h4>6. Severability</h4>
              <p> 6.1. If any provision of these terms is found to be invalid under any applicable law, such provisions shall be deleted without affecting the remaining provisions herein</p><br></br>
                           
            <h4>7. Variation of Terms</h4>
              <p> 7.1. Adbox Bangladesh is permitted to revise these terms at any time as it sees fit, and by using this website you are expected to review these terms regularly.</p><br></br>
                          
            <h4>8. Governing Law & Jurisdiction</h4>
              <p> 8.1. These terms will be governed by and construed in accordance with the laws of Bangladesh, and you submit to the non-exclusive jurisdiction of the state and federal courts located in Bangladesh for the resolution of any disputes.</p><br></br>

              <p> By using this website, you signify your acceptance of these terms and conditions. If you do not agree to these terms, please do not use our website. Your continued use of the website following the posting of changes to these terms will be deemed your acceptance of those changes.</p>
       </div>
      </div>
      {/* =================== service area end ===================*/}
    </>
  );
};

export default ServiceAreaGroup;
