import React, { useState, useEffect } from "react";
import NavbarTwo from "../components/NavbarTwo"; 
import Banner from "../components/Banner";
import AboutAreaTwo from "../components/AboutAreaTwo";
import ServiceArea from "../components/ServiceArea";
import WorkProcessOne from "../components/WorkProcessOne";
import Partner from "../components/Partner"; 
import BlogArea from "../components/BlogArea"; 
import Footer from "../components/Footer";
 
const HomeTwo = () => {
  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const isScrolled = window.scrollY > 0;
      setScrolled(isScrolled);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return (
    <>
      {/* Navigation Bar */}
      <NavbarTwo transparent={!scrolled} />

      {/* Banner */}
      <Banner />

      {/* About-Area  */}
      <AboutAreaTwo />

      {/* Service-Area */}
      <ServiceArea />

      {/* Work-Process */}
      <WorkProcessOne />

      {/* Partner */}
      <Partner />

      {/* Blog-Area */}
      <BlogArea />

      {/* Footer */}
      <Footer />
    </>
  );
};

export default HomeTwo;
