import React from "react";
import { FaRegFolderOpen, FaPlus, FaRegUser } from "react-icons/fa";
import { Link } from "react-router-dom";

const BlogAreaTwo = () => {
  return (
    <>
      {/*===================== Blog Area Two start =====================*/}
      <div className='blog-area pd-top-120 pd-bottom-90'>
        <div className='container'>
          <div className='row justify-content-center'>
            <div className='col-lg-8'>
              <div className='section-title text-center'>
                <h6 className='sub-title'>RECENT BLOG</h6>
                <h2 className='title'>
                 Read Our <span>Blogs</span>
                </h2>
              </div>
            </div>
          </div>
          <div className='row'>
            <div className='col-lg-4 col-md-6'>
             <a href='https://banglabox.net/article/997/%E0%A6%AA%E0%A7%8D%E0%A6%B0%E0%A6%A4%E0%A6%BF-%E0%A7%AA%E0%A7%A6-%E0%A6%B8%E0%A7%87%E0%A6%95%E0%A7%87%E0%A6%A8%E0%A7%8D%E0%A6%A1%E0%A7%87-%E0%A6%95%E0%A7%87%E0%A6%89-%E0%A6%A8%E0%A6%BE-%E0%A6%95%E0%A7%87%E0%A6%89-%E0%A6%86%E0%A6%A4%E0%A7%8D%E0%A6%AE%E0%A6%B9%E0%A6%A4%E0%A7%8D%E0%A6%AF%E0%A6%BE-%E0%A6%95%E0%A6%B0%E0%A6%9B%E0%A7%87!' target="_blank">
              <div className='single-blog-list style-2'>
                <div className='thumb'>
                  <img src='assets/img/blog/4.jpg' alt='img' />
                </div>
                <div className='details'> 
                  <h5 className='mb-3'>
                    <a  href='https://banglabox.net/article/997/%E0%A6%AA%E0%A7%8D%E0%A6%B0%E0%A6%A4%E0%A6%BF-%E0%A7%AA%E0%A7%A6-%E0%A6%B8%E0%A7%87%E0%A6%95%E0%A7%87%E0%A6%A8%E0%A7%8D%E0%A6%A1%E0%A7%87-%E0%A6%95%E0%A7%87%E0%A6%89-%E0%A6%A8%E0%A6%BE-%E0%A6%95%E0%A7%87%E0%A6%89-%E0%A6%86%E0%A6%A4%E0%A7%8D%E0%A6%AE%E0%A6%B9%E0%A6%A4%E0%A7%8D%E0%A6%AF%E0%A6%BE-%E0%A6%95%E0%A6%B0%E0%A6%9B%E0%A7%87!' target="_blank">
                    প্রতি ৪০ সেকেন্ডে কেউ না কেউ আত্মহত্যা করছে!
                    </a>
                  </h5>
                  <a className='read-more-text' href='https://banglabox.net/article/997/%E0%A6%AA%E0%A7%8D%E0%A6%B0%E0%A6%A4%E0%A6%BF-%E0%A7%AA%E0%A7%A6-%E0%A6%B8%E0%A7%87%E0%A6%95%E0%A7%87%E0%A6%A8%E0%A7%8D%E0%A6%A1%E0%A7%87-%E0%A6%95%E0%A7%87%E0%A6%89-%E0%A6%A8%E0%A6%BE-%E0%A6%95%E0%A7%87%E0%A6%89-%E0%A6%86%E0%A6%A4%E0%A7%8D%E0%A6%AE%E0%A6%B9%E0%A6%A4%E0%A7%8D%E0%A6%AF%E0%A6%BE-%E0%A6%95%E0%A6%B0%E0%A6%9B%E0%A7%87!' target="_blank">
                    Read More <FaPlus />
                  </a>
                </div>
              </div>
              </a>
            </div>
            <div className='col-lg-4 col-md-6'>
              <a href='https://banglabox.net/article/1002/%E0%A6%B9%E0%A6%BE%E0%A6%B8%E0%A6%BF%E0%A6%96%E0%A7%81%E0%A6%B6%E0%A6%BF-%E0%A6%AE%E0%A6%BE%E0%A6%A8%E0%A7%81%E0%A6%B7%E0%A7%87%E0%A6%B0-%E0%A6%B8%E0%A6%BE%E0%A6%A5%E0%A7%87-%E0%A6%A5%E0%A6%BE%E0%A6%95%E0%A7%81%E0%A6%A8,-%E0%A6%A8%E0%A6%BF%E0%A6%9C%E0%A7%87%E0%A6%93-%E0%A6%B9%E0%A6%BE%E0%A6%B8%E0%A7%81%E0%A6%A8%E0%A5%A4' target="_blank">
              <div className='single-blog-list style-2'>
                <div className='thumb'>
                  <img src='assets/img/blog/5.jpg' alt='img' />
                </div>
                <div className='details'> 
                  <h5 className='mb-3'>
                    <a href='https://banglabox.net/article/1002/%E0%A6%B9%E0%A6%BE%E0%A6%B8%E0%A6%BF%E0%A6%96%E0%A7%81%E0%A6%B6%E0%A6%BF-%E0%A6%AE%E0%A6%BE%E0%A6%A8%E0%A7%81%E0%A6%B7%E0%A7%87%E0%A6%B0-%E0%A6%B8%E0%A6%BE%E0%A6%A5%E0%A7%87-%E0%A6%A5%E0%A6%BE%E0%A6%95%E0%A7%81%E0%A6%A8,-%E0%A6%A8%E0%A6%BF%E0%A6%9C%E0%A7%87%E0%A6%93-%E0%A6%B9%E0%A6%BE%E0%A6%B8%E0%A7%81%E0%A6%A8%E0%A5%A4' target="_blank">
                    হাসিখুশি মানুষের সাথে থাকুন, নিজেও হাসুন।
                    </a>
                  </h5>
                  <a className='read-more-text' href='https://banglabox.net/article/1002/%E0%A6%B9%E0%A6%BE%E0%A6%B8%E0%A6%BF%E0%A6%96%E0%A7%81%E0%A6%B6%E0%A6%BF-%E0%A6%AE%E0%A6%BE%E0%A6%A8%E0%A7%81%E0%A6%B7%E0%A7%87%E0%A6%B0-%E0%A6%B8%E0%A6%BE%E0%A6%A5%E0%A7%87-%E0%A6%A5%E0%A6%BE%E0%A6%95%E0%A7%81%E0%A6%A8,-%E0%A6%A8%E0%A6%BF%E0%A6%9C%E0%A7%87%E0%A6%93-%E0%A6%B9%E0%A6%BE%E0%A6%B8%E0%A7%81%E0%A6%A8%E0%A5%A4' target="_blank">
                   Read More <FaPlus />
                  </a>
                </div>
              </div>
              </a>
            </div>
            <div className='col-lg-4 col-md-6'>
             <a href='https://banglabox.net/article/975/%E0%A6%85%E0%A6%B8%E0%A7%81%E0%A6%96-%E0%A6%AF%E0%A7%87%E0%A6%A8-%E0%A6%AD%E0%A7%8D%E0%A6%B0%E0%A6%AE%E0%A6%A3%E0%A7%87%E0%A6%B0-%E0%A6%B8%E0%A7%81%E0%A6%96-%E0%A6%AC%E0%A6%BF%E0%A6%98%E0%A7%8D%E0%A6%A8%E0%A6%BF%E0%A6%A4-%E0%A6%A8%E0%A6%BE-%E0%A6%95%E0%A6%B0%E0%A7%87' target="_blank">
              <div className='single-blog-list style-2'>
                <div className='thumb'>
                  <img src='assets/img/blog/6.png' alt='img' />
                </div>
                <div className='details'> 
                  <h5 className='mb-3'>
                    <a href='https://banglabox.net/article/975/%E0%A6%85%E0%A6%B8%E0%A7%81%E0%A6%96-%E0%A6%AF%E0%A7%87%E0%A6%A8-%E0%A6%AD%E0%A7%8D%E0%A6%B0%E0%A6%AE%E0%A6%A3%E0%A7%87%E0%A6%B0-%E0%A6%B8%E0%A7%81%E0%A6%96-%E0%A6%AC%E0%A6%BF%E0%A6%98%E0%A7%8D%E0%A6%A8%E0%A6%BF%E0%A6%A4-%E0%A6%A8%E0%A6%BE-%E0%A6%95%E0%A6%B0%E0%A7%87' target="_blank">
                    অসুখ যেন ভ্রমণের সুখ বিঘ্নিত না করে...
                    </a>
                  </h5>
                  <a className='read-more-text' href='https://banglabox.net/article/975/%E0%A6%85%E0%A6%B8%E0%A7%81%E0%A6%96-%E0%A6%AF%E0%A7%87%E0%A6%A8-%E0%A6%AD%E0%A7%8D%E0%A6%B0%E0%A6%AE%E0%A6%A3%E0%A7%87%E0%A6%B0-%E0%A6%B8%E0%A7%81%E0%A6%96-%E0%A6%AC%E0%A6%BF%E0%A6%98%E0%A7%8D%E0%A6%A8%E0%A6%BF%E0%A6%A4-%E0%A6%A8%E0%A6%BE-%E0%A6%95%E0%A6%B0%E0%A7%87' target="_blank">
                    Read More <FaPlus />
                  </a>
                </div>
              </div>
              </a>
            </div>
          </div>
        </div>
      </div>

      {/* ===================== BlogAreaTwo End =====================*/}
    </>
  );
};

export default BlogAreaTwo;
