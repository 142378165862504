import React from "react"; 
import { Link } from "react-router-dom";

const ServiceAreaTwo = () => {
  return (
    <>
      {/*=================== service area start ===================*/}
      <div className='service-area bg-gray bg-relative pd-top-120 pd-bottom-90'>
        <div className='container'>
          <div className='row justify-content-center'>
            <div className='col-lg-6'>
              <div className='section-title text-center'>
                <h6 className='sub-title'>OUR ADVANCED SERVICES</h6>
                <h2 className='title'>
                  Optimize Your <span>Performance</span> With ADBOX
                </h2>
              </div>
            </div>
          </div>
      
          <div className='row'>
            <div className='col-lg-4 col-md-6'>
              <div className='single-service-inner text-center'>
                <div className='thumb'>
                  <img src='assets/img/service/vrbt.png' alt='img' />
                </div>
                <div className='details'>  
                  <h5>
                     <a href="/service"> VRBT(Video Ring Back Tone) </a>
                  </h5> 
                  {/* <p> VRBT stands for Video Ring Back Tone.</p> */}
                </div>
              </div>
            </div> 
            
            <div className='col-lg-4 col-md-6'>
              <div className='single-service-inner text-center'>
                <div className='thumb'>
                 <img src='assets/img/service/esport.png' alt='img' />
                </div>
                <div className='details'> 
                  <h5>
                    <a href="/service">ESPORTS</a>
                  </h5>
                  {/* <p>WAP is a communications protocol that is used for wireless data access through most mobile wireless networks.</p> */}
                </div>
              </div>
            </div>

            <div className='col-lg-4 col-md-6'>
              <div className='single-service-inner text-center'>
                <div className='thumb'>
                 <img src='assets/img/service/wap.png' alt='img' />
                </div>
                <div className='details'> 
                  <h5>
                    <a href="/service" >WAP Services</a>
                  </h5>
                  {/* <p>WAP is a communications protocol that is used for wireless data access through most mobile wireless networks.</p>
                  <a className='btn btn-border-base' href='/service'>
                      See More  
                    </a> */}
                </div>
              </div>
            </div>

            <div className='col-lg-4 col-md-6'>
              <div className='single-service-inner text-center'>
                <div className='thumb'>
                  <img src='assets/img/service/ivr.png' alt='img' />
                </div>
                <div className='details'> 
                  <h5>
                    <a href='/service'>IVR</a>
                  </h5>
                  {/* <p>IVR is an automated phone system technology that allows incoming callers to access information via a voice response system of pre recorded messages without having to speak to an agent</p> */}
                </div>
              </div>
            </div>

            <div className='col-lg-4 col-md-6'>
              <div className='single-service-inner text-center'>
                <div className='thumb'>
                  <img src='assets/img/service/sms.png' alt='img' />
                </div>
                <div className='details'> 
                  <h5>
                  <a href='/service'>SMS</a>
                  </h5>
                  {/* <p>WAP is a communications protocol that is used for wireless data access through most mobile wireless networks.</p> */}
                </div>
              </div>
            </div>
             
             
            <div className='col-lg-4 col-md-6'>
              <div className='single-service-inner text-center'>
                <div className='thumb'>
                  <img src='assets/img/service/entertainment.png' alt='img' />
                </div>
                <div className='details'> 
                  <h5>
                    <a href="/service">Entertainment</a>
                  </h5>
                  {/* <p>WAP is a communications protocol that is used for wireless data access through most mobile wireless networks.</p> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* =================== service area end ===================*/}
    </>
  );
};

export default ServiceAreaTwo;
