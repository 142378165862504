import React, { useState } from "react";
import Slider from "react-slick";
const settings = { 
  dots: false,
  arrows: false,
  infinite: true,
  speed: 3501,  
  slidesToShow: 3,
  slidesToScroll: 1,
  initialSlide: 0,   
  cssEase: 'linear',  
  easing: 'ease-in-out',
  // pauseOnHover: false,  
  autoplay: true,
  autoplaySpeed: 3500,  
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: false,
          speed: 2000,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
          infinite: true,
          arrows: false,
          speed: 2000,
        },
      },
      {
        breakpoint: 575,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          arrows: false,
          speed: 2000,
        },
      },
    ],
  };
  const WorkProcessTwo = () => { 
  return (
    <>
      {/*==================== Work Process Two start ====================*/}
      <div className='work-process-area pd-top-200 pd-bottom-90'>
        <div className='container'>
          <div className='section-title text-center'>
            <h6 className='sub-title'>OUR PRODUCT</h6>
            <h4 className='title'>
              CHECK OUR ALL
            </h4>
            <h2 className='title'>
              <span>Digital</span> Products
            </h2>
          </div>
          <div className='row'>
          <Slider {...settings}>
            

          <div className='col-xl-3 col-md-6 mr '>
             <a href="https://www.engagewinner.com/home" target="_blank">
              <div className='single-work-process-inner'>
                <div className="mb-3">
                <a href="https://www.engagewinner.com/home" target="_blank"><img src='assets/img/digital_services/engge.png' alt='img' height={35}/></a>
                </div>
                <div className='details'>
                  {/* <p className='process-count'>Work 02</p> */}
                  <h5 className='mb-3'> <a href="https://www.engagewinner.com/home" target="_blank">Engage</a></h5>
                  <p className='content'>
                  <a href="https://www.engagewinner.com/home" target="_blank">Engage is a platform where users can play new and trending games participate in tournaments to win exciting gifts.</a>
                  </p>
                </div>
              </div>
              </a>
            </div>

            <div className='col-xl-3 col-md-6 mr'>
            <a href="http://gameboxbd.net/?mkeyid=d2lmaQ==" target="_blank">
              <div className='single-work-process-inner'>
                <div className="mb-3">
                <a href="http://gameboxbd.net/?mkeyid=d2lmaQ==" target="_blank"><img src='assets/img/digital_services/gmeone.png' alt='img' height={35}/></a>
                </div>
                <div className='details'>
                  {/* <p className='process-count'>Work 02</p> */}
                  <h5 className='mb-3'><a href="http://gameboxbd.net/?mkeyid=d2lmaQ==" target="_blank">Gamezone</a></h5>
                  <p className='content'>
                  <a href="http://gameboxbd.net/?mkeyid=d2lmaQ==" target="_blank">Gamezone offers users to have a variety of Android games and Java games under one platform.</a>
                  </p>
                </div>
              </div>
              </a>
            </div>

            <div className='col-xl-3 col-md-6 mr'>
            <a href="http://www.funboxbd.com/club/" target="_blank">
              <div className='single-work-process-inner'>
                <div className="mb-3">
                <a href="http://www.funboxbd.com/club/" target="_blank"><img src='assets/img/digital_services/funbox.png' alt='img' height={35} /></a>
                </div>
                <div className='details'>
                  {/* <p className='process-count'>Work 02</p> */}
                   <h5 className='mb-3'><a href="http://www.funboxbd.com/club/" target="_blank">Funbox</a></h5> 
                  <p className='content'>
                  <a href="http://www.funboxbd.com/club/" target="_blank">Funbox is our portal where consumers can get songs, telefilms, videos, animations wallpapers every entertainments in one portal.</a>
                  </p>
                </div>
              </div>
              </a>
            </div> 

            <div className='col-xl-3 col-md-6 mr'>
             <a href="http://daroonto.com/" target="_blank">
              <div className='single-work-process-inner'>
                <div className="mb-3">
                <a href="http://daroonto.com/" target="_blank"><img src='assets/img/digital_services/droonto.png' alt='img' height={35}/></a>
                </div>
                <div className='details'>
                  {/* <p className='process-count'>Work 02</p> */}
                  <h5 className='mb-3'> <a href="http://daroonto.com/" target="_blank">Daroonto</a></h5>
                  <p className='content'>
                  <a href="http://daroonto.com/" target="_blank">Daroonto provides users to get lifestyle tips, latest news in the world and many more type of blogs in one platform.</a>
                  </p>
                </div>
              </div>
              </a>
            </div>

            <div className='col-xl-3 col-md-6 mr'>
             <a href="#" target="_blank">
              <div className='single-work-process-inner'>
                <div className="mb-3">
                <a href="#" target="_blank"><img src='assets/img/digital_services/ivr.png' alt='img' height={35}/></a>
                </div>
                <div className='details'>
                  {/* <p className='process-count'>Work 02</p> */}
                  <h5 className='mb-3'> <a href="#" target="_blank">Islamer Alo</a></h5>
                  <p className='content'>
                  <a href="#" target="_blank">Islamer Alo is our IVR service. This service allows religious subscribers to get islamic lifestyle, tips etc. To get this service Dial 16265. </a>
                  </p>
                </div>
              </div>
              </a>
            </div>

            <div className='col-xl-3 col-md-6 mr'>
             <a href="https://www.youtube.com/channel/UCwv7XYUHis1WGaaeC4MD_NA" target="_blank">
              <div className='single-work-process-inner'>
                <div className="mb-3">
                <a href="https://www.youtube.com/channel/UCwv7XYUHis1WGaaeC4MD_NA" target="_blank"><img src='assets/img/digital_services/gnbox.png' alt='img' height={35}/></a>
                </div>
                <div className='details'>
                  {/* <p className='process-count'>Work 02</p> */}
                  <h5 className='mb-3'> <a href="https://www.youtube.com/channel/UCwv7XYUHis1WGaaeC4MD_NA" target="_blank">Gaanbox</a></h5>
                  <p className='content'>
                  <a href="https://www.youtube.com/channel/UCwv7XYUHis1WGaaeC4MD_NA" target="_blank">Gaanbox is our Youtube page. People can enjoy their favorite natok, telefims and songs from your Youtube page.</a>
                  </p>
                </div>
              </div>
              </a>
            </div>

            {/* <div className='col-xl-3 col-md-6 mr'>
            <a href="https://www.shemaroome.com/bd" target="_blank">
              <div className='single-work-process-inner'>
                <div className="mb-3">
                <a href="https://www.shemaroome.com/bd" target="_blank"><img src='assets/img/digital_services/shemaroome_logo.png' alt='img' height={40}/></a>
                </div>
                <div className='details'> 
                  <h5 className='mb-3'><a href="https://www.shemaroome.com/bd" target="_blank">Shemaroome</a></h5>
                  <p className='content'>
                  <a href="https://www.shemaroome.com/bd" target="_blank">Sheemaroo came together with Adbox to provide their content to the Bangladeshi consumers</a>
                  </p>
                </div>
              </div>
              </a>
            </div> */}

            <div className='col-xl-3 col-md-6 mr'>
            <a href="https://banglabox.net/" target="_blank">
              <div className='single-work-process-inner'>
                <div className="mb-3">
                <a href="https://banglabox.net/" target="_blank"><img src='assets/img/digital_services/bnglbox.png' alt='img' height={35}/></a>
                </div>
                <div className='details'>
                  {/* <p className='process-count'>Work 02</p> */}
                  <h5 className='mb-3'><a href="https://banglabox.net/" target="_blank">Banglabox</a></h5>
                  <p className='content'>
                  <a href="https://banglabox.net/" target="_blank">Our platform Banglabox provides funny entertainment blogs and celebrity news from all over the world for users to subscribe.</a>
                  </p>
                </div>
              </div>
              </a>
            </div>
            </Slider>
          </div>

        </div>
      </div> 
      {/* ==================== Work Process Two end ====================*/}
    </>
  );
};

export default WorkProcessTwo;
