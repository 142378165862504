import React from "react"; 

const ServiceAreaGroup = () => {
  return (
    <>
      {/*=================== service area start ===================*/}
      <div className='service-area bg-relative pd-top-100'>
        <img
          className='position-bottom-left top_image_bounce'
          src='assets/img/icon/4.png'
          alt='img'
        />
        <div className='container'>
         <p> Welcome to Adbox Bangladesh ("we," "us," or "our"). We are committed to protecting your privacy and providing a safe online experience for all users. This Privacy Policy outlines our practices regarding the collection, use, and disclosure of your information when you use our digital services.</p>
            <h4>1. Information We Collect</h4>   
              <p><span className="color">  Personal Information:</span> We may collect personal information, such as your name, email address, phone number, and other contact details, when you register for our services or interact with our platform.</p>  
              <p><span> Usage Information:</span> We automatically collect information about your use of our services, including but not limited to your IP address, device information, browser type, and pages you visit.</p><br></br> 

            <h4>2. How We Use Your Information</h4> 
              <p> <span> Providing Services:</span> We use your personal information to provide, maintain, and improve our services, as well as to communicate with you.</p>  
              <p> <span> Analytics:</span> We may use analytics tools to analyze trends, track user movements, and gather demographic information to improve our services and enhance your experience.</p><br></br> 

            <h4>3. Information Sharings</h4>
              <p> <span> Third-Party Service Providers:</span>We may share your information with third-party service providers who help us deliver and improve our services. These providers are obligated to keep your information confidential.</p>  
              <p> <span> Legal Compliance:</span>We may disclose your information if required by law or in response to a valid legal request.</p><br></br> 

            <h4>4. Security</h4>
              <p> We implement reasonable security measures to protect your information from unauthorized access, alteration, disclosure, or destruction.</p><br></br>
                           
            <h4>5. Cookies</h4>
              <p> We use cookies and similar technologies to enhance your experience, analyze usage patterns, and personalize content. You can manage your cookie preferences through your browser settings.</p><br></br>
                       
            <h4>6. Your Choices</h4>
              <p> You have the right to access, correct, or delete your personal information. You can exercise these rights by contacting us at info@adboxbd.com or +88 01860744898.</p>
              <p> You may opt out of receiving promotional communications from us by following the instructions in those communications.</p><br></br>
                           
            <h4>7. Children's Privacy</h4>
              <p> Our services are not directed to individuals under the age of 13. We do not knowingly collect personal information from children under 13. If we become aware that a child under 13 has provided us with personal information, we will take steps to delete such information.</p><br></br>
                          
            <h4>8. Changes to this Privacy Policy</h4>
              <p> We reserve the right to update or modify this Privacy Policy at any time. The most recent version will be posted on our website with the effective date.</p><br></br>

              <p> By using our services, you agree to the terms outlined in this Privacy Policy. Thank you for choosing Adbox Bangladesh.</p>
       </div>
      </div>
      {/* =================== service area end ===================*/}
    </>
  );
};

export default ServiceAreaGroup;
