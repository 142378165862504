import React, { useState } from "react";
import { FaPlus } from "react-icons/fa";
import { Link } from "react-router-dom";
import ModalVideo from "react-modal-video"; 
import VidyardPlayer from "react-player/vidyard";
const BannerTwo = () => {
  const [isOpen, setOpen] = useState(false);
  return (
    <>
      {/* ================== BannerTwo Start ==================*/}
      <div
        className='banner-area bg-relative banner-area-2 bg-cover'
        style={{ backgroundImage: 'url("./assets/img/bg/8.png")' }}
      >
        <div className='container pd-top-110'>
          <div className='row'> 
            <div className='col-lg-6 align-self-center'>
              <div className='banner-inner pe-xl-5'>
                <h2
                  className='title '
                  data-aos='fade-right'
                  data-aos-delay='200'
                  data-aos-duration='1500'
                >
                  {" "} 
                  <span> STAY TUNED </span>  
                </h2>
                <h2
                  className='title-1 '
                  data-aos='fade-right'
                  data-aos-delay='200'
                  data-aos-duration='1500'
                >
                  {" "} 
                  FOR THE <span> BIG REVEAL   </span>
                </h2>
                <p
                  className='content pe-xl-5 '
                  data-aos='fade-right'
                  data-aos-delay='250'
                  data-aos-duration='1500'
                >
                  VRBT is a ring-back media service that follows traditional usage models, where subscribers can either  purchase Video ring-back content from the mobile operator. The called party can place favorite Video and  assign it to separate number of contacts thus displaying it on calling party’s screen instead of the  traditional ringtone people used to listen upon initiating any call.
                </p>
                {/* <Link
                  className='btn btn-border-base '
                  data-aos='fade-right'
                  data-aos-delay='300'
                  data-aos-duration='1500'
                  to='/about'
                >
                  Discover More <FaPlus />
                </Link> */}
                
              </div>
            </div>
            
          <div
              className='col-lg-6 col-md-9 '
              data-aos='fade-left'
              data-aos-delay='100'
              data-aos-duration='1500'
            >
              <div className='banner-thumb-2 mt-4 mt-lg-0'>
                {/* <img
                  className='banner-animate-img banner-animate-img-4'
                  src='assets/img/banner-2/6.png'
                  alt='img'
                /> */}
                <div className='main-img-wrap'>
                  {/* <img
                    className='banner-animate-img banner-animate-img-1 left_image_bounce'
                    src='assets/img/banner-2/4.png'
                    alt='img'
                  /> */}
                  <img
                    className='banner-animate-img banner-animate-img-2 left_image_bounce'
                    src='assets/img/about/3.png'
                    alt='img'
                  /> 
                  <img
                    className='banner-animate-img banner-animate-img-3 top_image_bounce'
                    src='assets/img/about/2.png'
                    alt='img'
                  />
                  <video
                  className="main-img banner-animate-img-3"
                   loop
                   autoPlay
                   muted> 
                   <source src="assets/img/banner-2/vrbt.webm" type="video/webm" /> 
                  </video>
                          
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* ================== BannerTwo End ==================*/}
    </>
  );
};

export default BannerTwo;
