import React, { useState,useEffect  } from "react";
import { FaRoute, FaSearch } from "react-icons/fa"; 
import { Link, useLocation } from "react-router-dom";

const NavbarTwo = ({ transparent }) => {
  const navbarStyle = {
    background: transparent ? 'transparent' : '#fff',
  };

  const [activeItem, setActiveItem] = useState('/');
  const location = useLocation();

  useEffect(() => { 
    setActiveItem(location.pathname);
  }, [location.pathname]); 

  const activeStyle = {
    color: '#007FFF',
  };
 
  const inactiveStyle = {
    color: 'black',
  };
 
  const handleItemClick = (path) => {
    setActiveItem(path);
  };


  const [active, setActive] = useState(false);
  const [searchShow, setSearchShow] = useState(false);
  const menuActive = () => {
    setActive(!active);
  };
  const searchActive = () => {
    setSearchShow(!searchShow);
    console.log("hell");
  };

  // Control sidebar navigation
  let items = document.querySelectorAll(".menu-item-has-children > a");
  for (let i in items) {
    if (items.hasOwnProperty(i)) {
      items[i].onclick = function () {
        this.parentElement
          .querySelector(".sub-menu")
          .classList.toggle("active");
        this.classList.toggle("open");
      };
    }
  }
  return (
    <>
      {/* search popup start*/}
      <div
        className={searchShow ? "td-search-popup active" : "td-search-popup "}
        id='td-search-popup'
      >
        <form action='/' className='search-form'>
          <div className='form-group'>
            <input
              type='text'
              className='form-control'
              placeholder='Search.....'
            />
          </div>
          <button type='submit' className='submit-btn'>
            <FaSearch />
          </button>
        </form>
      </div>
      {/* search popup end*/}
      <div
        onClick={searchActive}
        className={searchShow ? "body-overlay active" : "body-overlay"}
        id='body-overlay'
      ></div>
      {/* ==================== Navbar Two Start ====================*/}
      <nav className='navbar navbar-area navbar-area-2 navbar-expand-lg' style={navbarStyle}>
        <div className='container nav-container'>
          <div className='responsive-mobile-menu'>
            <button
              onClick={menuActive}
              className={
                active
                  ? "menu toggle-btn d-block d-lg-none open"
                  : "menu toggle-btn d-block d-lg-none"
              }
              data-target='#itech_main_menu'
              aria-expanded='false'
              aria-label='Toggle navigation'
            >
              <span className='icon-left' />
              <span className='icon-right' />
            </button>
          </div>
          <div className='logo'>
            <Link to='/'>
              <img src='assets/img/main-logo-1.png' alt='img' />
            </Link>
          </div>
          {/* <div className='nav-right-part nav-right-part-mobile'>
            <span className='search-bar-btn' onClick={searchActive}>
              <FaSearch />
            </span>
          </div> */}
          <div
            className={
              active
                ? "collapse navbar-collapse sopen"
                : "collapse navbar-collapse"
            }
            id='itech_main_menu'
          >
            <ul className='navbar-nav menu-open text-lg-end'>
             <li>
               <a  href='/'
                   style={activeItem === '/' ? activeStyle : inactiveStyle}
                   onClick={() => handleItemClick('/')}>
                   Home
               </a>
             </li>

             <li>
                <a href='/about' 
                   style={activeItem === '/about' ? activeStyle : inactiveStyle}
                   onClick={() => handleItemClick('/about')}>
                  About Us
                </a>
             </li>

             <li>
                <a href='/service' 
                   style={activeItem === '/service' ? activeStyle : inactiveStyle} 
                   onClick={() => handleItemClick('/service')}>
                   Service
                </a>
             </li>

             <li>
                <a href='/product' 
                   style={activeItem === '/product' ? activeStyle : inactiveStyle}
                   onClick={() => handleItemClick('/product')}>
                   Products
                </a>
             </li>

             <li>
                 <a href='/mvas'
                    style={activeItem === '/mvas' ? activeStyle : inactiveStyle}
                    onClick={() => handleItemClick('/mvas')}>
                    MVAS
                 </a>
             </li>

             <li>
                  <a href='/contact' 
                     style={activeItem === '/contact' ? activeStyle : inactiveStyle}
                     onClick={() => handleItemClick('/contact')}>
                     Contact Us
                 </a>
             </li>
           </ul>
          </div>
          <div className='nav-right-part nav-right-part-desktop align-self-center'>
            <Link className='btn btn-border-base' to='/faq'>
              FAQ <FaRoute />
            </Link>
          </div>
        </div>
      </nav>
      {/* ==================== Navbar Two end ====================*/}
    </>
  );
};

export default NavbarTwo;
