import React, { useState } from "react";
import { FaPlus } from "react-icons/fa";
import { Link } from "react-router-dom";
import ModalVideo from "react-modal-video"; 
import VidyardPlayer from "react-player/vidyard";
const BannerTwo = () => {
  const [isOpen, setOpen] = useState(false);
  return (
    <>
      {/* ================== BannerTwo Start ==================*/}
      <div
        className='banner-area bg-relative banner-area-2 bg-cover'
        style={{ backgroundImage: 'url("./assets/img/bg/8.png")' }}
      >
        <div className='container pd-top-100'>
          <div className='row'>
            <div className='col-lg-6 align-self-center'>
              <div className='banner-inner pe-xl-5'>
                <h6
                  className='subtitle '
                  data-aos='fade-right'
                  data-aos-delay='100'
                  data-aos-duration='1500'
                >
                  Think Digital, Go Digital
                </h6>
                <h6
                  className='title-1 '
                  data-aos='fade-right'
                  data-aos-delay='200'
                  data-aos-duration='1500'
                >
                  {" "} 
                  ADDING <span>VALUE</span> TO THE NEXT LEVEL
                </h6>
                <p
                  className='content pe-xl-5 '
                  data-aos='fade-right'
                  data-aos-delay='250'
                  data-aos-duration='1500'
                >
                  Adbox is always looking to generate something new, as a part of this activity we are working with Digital Product and management services with a focus on Digital Era.
                </p>
                {/* <Link
                  className='btn btn-border-base '
                  data-aos='fade-right'
                  data-aos-delay='300'
                  data-aos-duration='1500'
                  to='/about'
                >
                  Discover More <FaPlus />
                </Link> */}
                <div
                  className='d-inline-block align-self-center '
                  data-aos='fade-right'
                  data-aos-delay='350'
                  data-aos-duration='1500'
                >
                  <span
                    onClick={() => setOpen(true)}
                    className='video-play-btn-hover'
                  >
                    <a href="/product">
                    <img src='assets/img/video.svg' alt='img' />{" "}
                    <h6 className='d-inline-block'>       Explore Our Latest Product </h6></a>
                  </span>

                  <div></div>
                </div>
                {/* <ModalVideo
                  channel='youtube'
                  autoplay
                  isOpen={isOpen}
                  videoId='QR7PVmN5QtE'
                  onClose={() => setOpen(false)}
                /> */}
              </div>
            </div>
            <div
              className='col-lg-6 col-md-9 '
              data-aos='fade-left'
              data-aos-delay='100'
              data-aos-duration='1500'
            >
              <div className='banner-thumb-2 mt-4 mt-lg-0'>
                {/* <img
                  className='banner-animate-img banner-animate-img-4'
                  src='assets/img/banner-2/6.png'
                  alt='img'
                /> */}
                <div className='main-img-wrap'>
                  {/* <img
                    className='banner-animate-img banner-animate-img-1 left_image_bounce'
                    src='assets/img/banner-2/4.png'
                    alt='img'
                  />
                  <img
                    className='banner-animate-img banner-animate-img-2 left_image_bounce'
                    src='assets/img/banner-2/5.png'
                    alt='img'
                  />  */}
                  <video
                  className="main-img"
                   loop
                   autoPlay
                   muted> 
                   <source src="assets/img/banner-2/web.webm" type="video/webm" /> 
                  </video>
                          
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* ================== BannerTwo End ==================*/}
    </>
  );
};

export default BannerTwo;
