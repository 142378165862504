import React from "react";
import {
  FaArrowRight,
  FaCalendarAlt,
  FaEnvelope,
  FaFacebookF,
  FaInstagram,
  FaMapMarkedAlt,
  FaPhoneAlt,
  FaTwitter,
  FaYoutube,
  FaLinkedinIn, 
} from "react-icons/fa";
import { Link } from "react-router-dom";

const FooterOne = () => {
  return (
    <>
      {/* ================== Footer One Start ==================*/}
      <footer className='footer-area bg-black bg-cover'>
        {/* <div className='footer-subscribe'>
          <div className='container'>
            <div
              className='footer-subscribe-inner bg-cover'
              style={{ backgroundImage: 'url("./assets/img/bg/6.png")' }}
            >
              <div className='row'>
                <div className='col-lg-6'>
                  <h2 className='mb-lg-0 mb-3'>Subscribe To Our Newsletter</h2>
                </div>
                <div className='col-lg-6 align-self-center text-lg-end'>
                  <input type='text' placeholder='Your e-mail address' />
                  <a className='btn btn-black border-radius-0' href='#'>
                    Submit now
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div> */}
        <div className='container py-5'>
          <div className='row'>
          <div className='col-lg-1 col-md-6 '></div>
            <div className='col-lg-3 col-md-6 '>
              <div className='widget widget_about'>
                <div className='thumb'>
                  <img src='assets/img/logo-adbox-bangladesh-white.png' alt='img' />
                </div>
                <div className='details'>
                  <p>
                  Adbox provides latest services to their customers at the best performance through the company’s relationship
                   and access to International Aggregators and VAS companies.
                  </p>
                  
                </div>
              </div>
            </div>
            <div className='col-lg-1 col-md-6 '></div>
            <div className='col-lg-3 col-md-6'>
              <div className='widget widget_nav_menu'>
                <h4 className='widget-title'>Our Service</h4>
                <ul>
                  <li>
                    <a href='/service'>
                      <FaArrowRight /> Video Ring Back Tone
                    </a>
                  </li>
                  
                  <li>
                     <a href="/service" target="_blank"><FaArrowRight />Esports</a>
                  </li>
                  
                  <li>
                    <a href="/service" target="_blank"><FaArrowRight />WAP Services</a>
                  </li> 

                  <li>
                    <a href='/service'>
                      <FaArrowRight /> IVR
                    </a>
                  </li>  
                  <li>
                     <a href="/service" target="_blank"><FaArrowRight />Entertainment</a>
                  </li>
                </ul>
              </div>
            </div>
             
            <div className='col-lg-3 col-md-6'>
              <div className='widget widget-recent-post'>
                <h4 className='widget-title'>Contact us</h4>
                <div className='widget widget_contact'>
                  <ul className='details'>
                    <li>
                      <FaMapMarkedAlt />
                       Dhaka, Bangladesh
                    </li>
                    <li className='mt-3'>
                      <FaPhoneAlt />+880 1860 744 898
                    </li>
                    <li className='mt-2'>
                      <FaEnvelope /> info@adboxbd.com
                    </li>
                  </ul>
                  <ul className='social-media mt-4'>
                    <li>
                      <a href='https://www.facebook.com/adboxbangladesh' target="_blank">
                        <FaFacebookF />
                      </a>
                    </li>
                    <li>
                      <a href='https://twitter.com/AdboxB' target="_blank">
                        <FaTwitter />
                      </a>
                    </li>
                    <li>
                      <a href='https://www.instagram.com/adbox_bd/' target="_blank">
                        <FaInstagram />
                      </a>
                    </li>
                    <li>
                      <a href='https://www.youtube.com/channel/UCwv7XYUHis1WGaaeC4MD_NA' target="_blank">
                        <FaYoutube />
                      </a>
                    </li>
                    <li>
                      <a href='https://www.linkedin.com/company/adbox-bangladesh/' target="_blank">
                        <FaLinkedinIn />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='footer-bottom'>
          <div className='container'>
            <div className='row'>
              <div className='col-md-6 align-self-center'>
                <p>© AdboxBangladesh 2023 | All Rights Reserved</p>
              </div>
              <div className='col-md-6 text-lg-end'>
                <a href='/term&con'>Terms &amp; Condition</a><span>&#x7c;</span>
                <a href='/privacy'>Privacy Policy </a><span>&#x7c;</span>
                <a href='/faq'>FAQ  </a><span>&#x7c;</span>
                <a href='/contact'>Contact Us </a>
              </div>
            </div>
          </div>
        </div>
      </footer>
      {/* ================== Footer One  end ==================*/}
    </>
  );
};

export default FooterOne;
