import React from "react";

const FaqAreaOne = () => {
  return (
    <>
      {/*==================== Faq area start ====================*/}
      <div
        className='faq-area faq-area-margin-top bg-cover pd-top-90 pd-bottom-110'
        style={{ backgroundImage: 'url("./assets/img/bg/10.png")' }}
      >
        <div className='container'>
          <div className='row pd-top-200'>
            {/* <div
              className='col-xl-5 col-lg-6 col-md-8 order-lg-last'
              data-aos='fade-left'
              data-aos-delay='100'
              data-aos-duration='1500'
            >
              <div className='about-thumb-inner pt-lg-3'>
                <img
                  className='main-img'
                  src='assets/img/about/4.png'
                  alt='img'
                />
                <img
                  className='animate-img-bottom-right top_image_bounce'
                  src='assets/img/about/5.png'
                  alt='img'
                />
              </div>
            </div> */}
            <div
              className='col-xl-7 col-lg-7'
              data-aos='fade-left'
              data-aos-delay='100'
              data-aos-duration='1500'
            >
              <div className='section-title mb-0 mt-4 mt-lg-0'>
                <h6 className='sub-title'>SOME FAQ'S</h6>
                <h2 className='title'>
                  {/* Creating <span>Digital</span> Masterpieces */}
                </h2>
                <p className='content'>
                  {/* It is a long established fact that a reader will be distr
                  acted bioiiy the end gail designa readable content of a page
                  when looking. */}
                </p>
              </div>
              <div
                className='accordion accordion-inner style-2 accordion-icon-left mt-3'
                id='accordionExample'
              >
                <div className='accordion-item'>
                  <h2 className='accordion-header' id='headingOne'>
                    <button
                      className='accordion-button'
                      type='button'
                      data-bs-toggle='collapse'
                      data-bs-target='#collapseOne'
                      aria-expanded='true'
                      aria-controls='collapseOne'
                    >
                      1.	How to avail of our services?
                    </button>
                  </h2>
                  <div
                    id='collapseOne'
                    className='accordion-collapse collapse show'
                    aria-labelledby='headingOne'
                    data-bs-parent='#accordionExample'
                  >
                    <div className='accordion-body'>
                      <p>To play games in our <span> Gamezone</span> services Type “Start GMB” and send it to 16265 or go to  👉<a href="http://gameboxbd.net/?mkeyid=d2lmaQ==">http://gameboxbd.net/?mkeyid=d2lmaQ==</a> to JOIN using the SUBSCRIBE button or dial *16265*1*1# </p>
                      <p>To watch short video clips, listen to songs, or download wallpaper in <span>FUNBOX</span> Type “Start FB” and send to 16265 or 👉<a href="http://www.funboxbd.com/club/">http://www.funboxbd.com/club/ </a> to JOIN using the SUBSCRIBE button or dial *16265*2*1# </p>
                      <p>To listen to our <span>ISLAMER ALO</span> IVR service type “ALO” and send it to 16265 or call on 16265 or dial *16265*3*1# for IVR (Banglalink) </p>
                      <p>Click 👉<a href="http://cysgames.com/"> http://cysgames.com/ </a> and SUBSCRIBE to play amazing games on our gaming portal <span>CYCAS</span> (Robi/Airtel users)</p>
                      <p>To subscribe to our infotainment service <span>DAROONTO</span>, Click 👉<a href="http://daroonto.com/"> http://daroonto.com/ </a> (Teletalk users only)  </p>
                      <p>To enjoy our entertainment site <span>GAANBOX</span>, visit 👉<a href="http://bit.ly/SubscribeGaanbox"> http://bit.ly/SubscribeGaanbox </a> </p>
                      <p>To stay up to date with regular news updates on our blog visit 👉<a href="https://banglabox.net/"> https://banglabox.net/ </a> </p>
                    </div>
                  </div>
                </div>
                <div className='accordion-item'>
                  <h2 className='accordion-header' id='headingTwo'>
                    <button
                      className='accordion-button collapsed'
                      type='button'
                      data-bs-toggle='collapse'
                      data-bs-target='#collapseTwo'
                      aria-expanded='false'
                      aria-controls='collapseTwo'
                    >
                     2.	How to register in Engage? 
                    </button>
                  </h2>
                  <div
                    id='collapseTwo'
                    className='accordion-collapse collapse'
                    aria-labelledby='headingTwo'
                    data-bs-parent='#accordionExample'
                  >
                    <div className='accordion-body'>
                       <p> Register in <span>ENGAGE</span> visit <a href="https://www.engagewinner.com/home">https://www.engagewinner.com/home</a> then click to register or log in to subscribe to engage. (ROBI or Airtel users only) </p> 
                    </div>
                  </div>
                </div>
                <div className='accordion-item'>
                  <h2 className='accordion-header' id='headingThree'>
                    <button
                      className='accordion-button collapsed'
                      type='button'
                      data-bs-toggle='collapse'
                      data-bs-target='#collapseThree'
                      aria-expanded='false'
                      aria-controls='collapseThree'
                    >
                     3.	How to participate in an ENGAGE tournament? 
                    </button>
                  </h2>
                  <div
                    id='collapseThree'
                    className='accordion-collapse collapse'
                    aria-labelledby='headingThree'
                    data-bs-parent='#accordionExample'
                  >
                    <div className='accordion-body'>
                      <p>After successfully completing the subscription process click on Tournament and put your in-game name (IGN) after that click on the register button and you’ll get a confirmation message. </p>
                    </div>
                  </div>
                </div>
                <div className='accordion-item'>
                  <h2 className='accordion-header' id='headingTwo'>
                    <button
                      className='accordion-button collapsed'
                      type='button'
                      data-bs-toggle='collapse'
                      data-bs-target='#collapseTwo'
                      aria-expanded='false'
                      aria-controls='collapseTwo'
                    >
                     4.	How to get in touch with us?
                    </button>
                  </h2>
                  <div
                    id='collapseTwo'
                    className='accordion-collapse collapse'
                    aria-labelledby='headingTwo'
                    data-bs-parent='#accordionExample'
                  >
                    <div className='accordion-body'>
                       <p> If you've any new ideas in any value-added services or digital products that you want to collaborate, contact with us from our <a href="/contact">‘Contact us’</a> tab.   </p> 
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* ==================== Faq area end ====================*/}
    </>
  );
};

export default FaqAreaOne;
