import React from "react";
import { FaStar } from "react-icons/fa";
import Slider from "react-slick";

const TestimonialThree = () => {
  const settings = {
    dots: false,
    arrows: false,
    infinite: true,
    speed: 3501,  
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 0,   
    cssEase: 'linear',  
    easing: 'ease-in-out',
    pauseOnHover: false,  
    autoplay: true,
    autoplaySpeed: 3500, 
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: false,
          speed: 2000,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
          infinite: true,
          arrows: false,
          speed: 2000,
        },
      },
      {
        breakpoint: 575,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          arrows: false,
          speed: 2000,
        },
      },
    ],
  };
  return (
    <>
      {/* =================== Testimonial Three Start ===================*/}
      <div
        className='testimonial-area bg-cover pd-top-120 pd-bottom-120'
        style={{ backgroundImage: 'url("./assets/img/bg/13.png")' }}
      >
        <div className='container pd-bottom-100'>
          <div className='row justify-content-center'>
            <div className='col-xl-7 col-lg-9'>
              <div className='section-title text-center'>
                <h6 className='sub-title'>OUR PARTNER</h6>
                <h4 className='title'>CHECK OUR ALL</h4>
                <h2 className='title'>All <span>Partners</span></h2>
              </div>
            </div>
          </div>
          <div className='testimonial-slider-2 owl-carousel  '>
            <Slider {...settings}> 
                  <div className='row'>
                    <div className='col-md-5'>
                      <div className='thumb'> 
                        <img
                          className='main-img'
                          src='assets/img/partner/airtel.png'
                          alt='img'
                        />
                      </div>
                    </div> 
                  </div>
                 

                  <div className='row'>
                    <div className='col-md-5'>
                      <div className='thumb'> 
                        <img
                          className='main-img'
                          src='assets/img/partner/b-link.png'
                          alt='img'
                        />
                      </div>
                    </div> 
                  </div>

                  {/* <div className='row'>
                    <div className='col-md-5'>
                      <div className='thumb'> 
                        <img
                          className='main-img'
                          src='assets/img/partner/gp.png'
                          alt='img'
                        />
                      </div>
                    </div> 
                  </div> */}

                  <div className='row'>
                    <div className='col-md-5'>
                      <div className='thumb'> 
                        <img
                          className='main-img'
                          src='assets/img/partner/robi.png'
                          alt='img'
                        />
                      </div>
                    </div> 
                  </div>

                  <div className='row'>
                    <div className='col-md-5'>
                      <div className='thumb'> 
                        <img
                          className='main-img'
                          img src='assets/img/partner/t-talk.png'
                          alt='img'
                        />
                      </div>
                    </div> 
                  </div>
              
            </Slider>
          </div>
        </div>
      </div>

      {/* =================== Testimonial Three End ===================*/}
    </>
  );
};

export default TestimonialThree;
