import React, { useState, useEffect } from "react";
import NavbarTwo from "../components/NavbarTwo"; 
import Breadcrumb from "../components/Breadcrumb";
import ServiceAreaGroup from "../components/ServiceAreaGroup";
import Footer from "../components/Footer";

const Service = () => {
  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const isScrolled = window.scrollY > 0;
      setScrolled(isScrolled);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return (
    <>
      {/* Navigation Bar */}
      <NavbarTwo transparent={!scrolled}/>

      {/* Breadcrumb Bar */}
      {/* <Breadcrumb title={"Service"} /> */}

      {/* ServiceAreaGroup   */}
      <ServiceAreaGroup />

      {/* Footer */}
      <Footer />
    </>
  );
};

export default Service;
