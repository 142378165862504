import { BrowserRouter, Routes, Route } from "react-router-dom"; 
import HomeTwo from "./pages/HomeTwo";
import Service from "./pages/Service";  
import About from "./pages/About";  
import Product from "./pages/Product";
import MVAS from "./pages/MVAS"; 
import Contact from "./pages/Contact";  
import TermCondition from "./pages/TermCondition";  
import Privacy from "./pages/Privacy"; 
import Vrbt from "./pages/Vrbt"; 
import Faq from "./pages/Faq"; 
import AOS from "aos";
import "aos/dist/aos.css";
import { useEffect } from "react";
import ScrollToTop from "react-scroll-to-top"; 
function App() {
  useEffect(() => {
    AOS.init({
      offset: 0,
      easing: "ease",
      once: true,
    });
    AOS.refresh();
  }, []);
  return (
    <BrowserRouter>
      <Routes> 
        <Route exact path='/' element={<HomeTwo />} /> 
        <Route exact path='/service' element={<Service />} /> 
        <Route exact path='/about' element={<About />} /> 
        <Route exact path='/product' element={<Product />} />
        <Route exact path='/mvas' element={<MVAS />} />  
        <Route exact path='/contact' element={<Contact />} /> 
        <Route exact path='/term&con' element={<TermCondition />} />
        <Route exact path='/privacy' element={<Privacy />} />
        <Route exact path='/vrbt' element={<Vrbt />} />
        <Route exact path='/faq' element={<Faq />} />
      </Routes>
      <ScrollToTop smooth color='#246BFD' />
    </BrowserRouter>
  );
}

export default App;
