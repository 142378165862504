import React, { useState, useEffect } from "react";
import NavbarTwo from "../components/NavbarTwo"; 
import Vrbt from "../components/Vrbt";
import Footer from "../components/Footer";
 
const HomeTwo = () => {
  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const isScrolled = window.scrollY > 0;
      setScrolled(isScrolled);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return (
    <>
      {/* Navigation Bar */}
      <NavbarTwo transparent={!scrolled}/>

      {/* Vrbt */}
      <Vrbt />

      {/* Footer */}
      <Footer />
    </>
  );
};

export default HomeTwo;
